
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
  },
  components: {},
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    // const borderColor = getCSSVariableValue("--bs-gray-200");
    // const baseColor = getCSSVariableValue("--bs-info");
    const lightColor = getCSSVariableValue("--bs-light-info");

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        width: 340,
        type: "donut",
        //height: props.chartHeight,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700",
            },
            value: {
              color: labelColor,
              fontSize: "12px",
              fontWeight: "700",
              offsetY: 12,
              show: true,
              formatter: function (val) {
                return val + "";
              },
            },
          },
          track: {
            background: lightColor,
            strokeWidth: "100%",
          },
        },
      },
      colors: ["#58D68D", "#F95700FF", "#9B59B6"],

      stroke: {
        lineCap: "round",
      },
      labels: ["600 Full Bins", "100 OverFlowing", "50 Garbage Around"],
    };
    const series = [1000, 200, 300];

    return {
      series,
      chartOptions,
    };
  },
});
